import React from "react";
import {
  Card,
  Icon,
  Flag,
  Dropdown,
  Button,
  Checkbox,
} from "semantic-ui-react";
import axios from "axios";
import { connect } from "react-redux";
import moment from "moment";
import ContractWidget from "./ContractWidget";
import BasicSeasonStats from "../../../components/data-providers/instat/player/BasicSeasonStats";
import { Dialog, DialogContent } from "@material-ui/core";

import EditPlayerDetails from "./EditPlayerDetails";
import { store } from "react-notifications-component";
import PlayerTransactions from "./PlayerTransactions";
import PlayerVideoHighlightsWidget from "./PlayerVideoHighlightsWidget";
import TrainingStatsWidget from "./TrainingStatsWidget";
import MedicalPlayerProfile from "../../Medical/MedicalPlayerProfile";
import ExpandedImage from "../../SiteAdmin/Photos/ExpandedImage";
import PaymentsToClub from "../../Financial/PaymentsToClub/PaymentsToClub";
import { Redirect } from "react-router-dom";
import NationalTeamCallUpWidget from "../PlayerProfile/NationalTeamCallUp/NationalTeamCallUpWidget";
import BasicLastMatchesStats from "../../../components/data-providers/instat/player/BasicLastMatchesStats";
import TransferHistory from "./TransferHistory";
import DocumentsWidget from "./DocumentsWidget";
import PlayerRadarChart from "../../../components/data-providers/instat/player/PlayerRadarChart";
import Select from "react-select";
import { baseUrl, isTFB, setURLParam } from "../../../utils/utilFunctions";
import Loader from "react-loader-spinner";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import AddInjuryForm from "../../Medical/Injuries/AddInjuryForm";
import AddNotes from "./Notes/AddNotes";

import _ from "lodash";
import { bindActionCreators } from "redux";
import { updateQuickSetup } from "../../../utils/api";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import PlayerTeams from "./PlayerTeams";

class PlayerProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicInfo: [],
      phys_report_category: "1",
      selected_test_id: "",
      past_tests: [],
      injury_dialog_opened: false,
      addedImage: "",

      seasons: [],
      current_season: "",
      selected_season: new URLSearchParams(this.props.location.search).get(
        "id_season"
      ),
      season_test: null,

      editDetailsPopup: false,
      deletePlayerPopup: false,
      movePlayerPopup: false,
      moveTeamNew: "",

      expandedImage: false,
      expandedImageSrc: "",

      show_payments_to_club: true,
      hasAccessToPlayer: false,
      redirectToTeams: false,

      club_teams: [],
      selected_new_team: null,
      keep_in_previous_teams: false,
      player_teams: [],
      selected_player_teams: [],

      delete_player_from_team_popup: false,
      delete_from_team: null,

      isLoading: true,

      add_new_injury: false,
      openAddNotesPopup: false,
      viewModeNotePopup: false,

      player_notes: [],
      last_note: null,

      refreshInjuries: false,

      loading_stats: true,
      stats: {},

      competitions: [],
    };
  }
  dropdownRef = React.createRef();
  imgInput = React.createRef();

  componentDidMount = () => {
    this.hasAccessToPlayer();
  };

  hasAccessToPlayer = () => {
    axios
      .get(
        `user/has_access_to_player?id_player=${this.props.match.params.id}&id_user=${this.props.currentUser.id_user}`
      )
      .then((res) => {
        if (res.data == 1) {
          this.setState(
            {
              hasAccessToPlayer: true,
            },
            this.getSeasons
          );
        } else {
          this.setState({
            redirectToTeams: true,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  getSeasons = () => {
    axios
      .get(
        `club/get_seasons_for_player_2?id_player=${this.props.match.params.id}&id_season=${this.state.selected_season}`
      )
      .then((res) => {
        const param_id_season = new URLSearchParams(
          this.props.location.search
        ).get("id_season");
        this.setState(
          {
            seasons: res.data.seasons,
            current_season: res.data.selected_season,
            selected_season: res.data.selected_season,
          },
          () => {
            this.getPlayerNotes();
            this.getBasicInfo();
            this.getPastTests();
            this.getClubCompetitions();
          }
        );
      })
      .catch((err) => console.log(err));
  };

  getBasicInfo = () => {
    this.setState({ isLoading: true }, () => {
      axios
        .get(
          `player/get_basic_info_2?id_player=${this.props.match.params.id}&id_season=${this.state.selected_season}`
        )
        .then((res) =>
          this.setState({
            basicInfo: res.data.basic_info,
            club_teams: res.data.club_teams,
            player_teams: res.data.player_teams,
            isLoading: false,
            addedImage: "",
          })
        )
        .catch((err) => console.log(err));
    });
  };

  getPastTests = () => {
    axios
      .get(`player/get_player_tests?id_player=${this.props.match.params.id}`)
      .then((res) => this.setState({ past_tests: res.data }))
      .catch((err) => console.log(err));
  };

  getPlayerNotes = () => {
    axios
      .get(`player/get_player_notes?id_player=${this.props.match.params.id}`)
      .then((res) => {
        let playerNotes = res.data;

        // CHANGE FORMAT FOR DATE FROM "22.12.2015 13:45:23" TO "22.12.2015"
        playerNotes = _.map(playerNotes, (note) => {
          return {
            ...note,
            date_add: moment(note.date_add).format("DD.MM.YYYY"),
            last_edited: note.last_edited
              ? moment(note.last_edited).format("DD.MM.YYYY")
              : note.last_edited,
          };
        });
        this.setState({
          player_notes: playerNotes,
          last_note: playerNotes[res.data.length - 1] || null,
        });
      })
      .catch((err) => console.log(err));
  };

  getClubCompetitions = () => {
    axios
      .get(
        `player/get_competition_list_by_club?id_club=${this.props.currentUser.id_club}&friendly=1`
      )
      .then((res) => {
        this.setState(
          {
            competitions: _.map(res.data?.list ?? [], (e) => ({
              ...e,
              checked: true,
            })),
            loading_stats: true,
          },
          this.getStats
        );
      });
  };

  getStats = () => {
    axios
      .get(
        `player/player_season_stats?id_player=${this.props.match.params.id
        }&id_season=${this.state.selected_season}&id_competition=${_.join(
          _.map(
            _.filter(this.state.competitions, (e) => e.checked),
            (e) => e.id_competition
          ),
          "_"
        )}`
      )
      .then((res) => {
        this.setState({ stats: res.data, loading_stats: false });
      });
  };

  onChangeDropdown = (e, value) => {
    this.setState({ [value.name]: value.value }, () => {
      if (value.name == "selected_season") {
        this.getBasicInfo();
        setURLParam("id_season", value.value);
      }
    });
  };

  updateCompetitions = (data) => {
    this.setState({ competitions: data, loading_stats: true }, this.getStats);
  };

  handleItemClick = (_e, x) => {
    this.dropdownRef.current.handleItemClick(_e, x);
  };

  isAllowedFinancial = () => {
    return this.props.currentUser.user_permissions.FINANCIAL;
  };

  handleChangeImage = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.setState({
        fileSection: file,
        addedImage: reader.result,
      });
      this.imgInput.current.value = "";
    };
  };

  addDefaultSrc = (event) => {
    event.target.src = baseUrl(`uploads/players/placeholder.png`);
  };

  addImage = () => {
    const data = new FormData();
    data.append("image", this.state.addedImage);
    data.append("id_player", this.props.match.params.id);

    axios
      .post("player/add_player_image", data)
      .then(() => {
        this.getBasicInfo();
        store.addNotification({
          title: "Profil jucator",
          message: "Imaginea a fost incarcata cu succes!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      })
      .catch((err) => console.log(err));
  };

  changeImage = () => {
    const data = new FormData();
    data.append("image", this.state.addedImage);
    data.append("id_player", this.props.match.params.id);

    axios
      .post("player/change_player_image", data)
      .then(() => {
        this.getBasicInfo();
        store.addNotification({
          title: "Profil jucator",
          message: "Imaginea a fost modificata cu succes!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      })
      .catch((err) => console.log(err));
  };

  deletePlayer = () => {
    const id = this.props.match.params.id;
    const idClub = this.props.currentUser.id_club;

    const payload = {
      id_player: id,
      id_club: idClub,
      is_tfb: isTFB(),
    };

    axios.post(`player/delete_player_2`, payload).then((res) => {
      if (res.data.success == 1) {
        store.addNotification({
          title: "Profil jucator",
          message: "Jucatorul a fost eliminat cu succes!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });

        let clubQuickSetup = res.data.club_quick_setup;
        this.props.updateQuickSetup({
          ...clubQuickSetup,
          isSetupOpen: false,
        });

        this.props.history.goBack();
      }
    });
  };

  movePlayer = () => {
    const payload = {
      id_player: this.props.match.params.id,
      id_new_team: this.state.selected_new_team.value,
      keep_in_previous_teams: this.state.keep_in_previous_teams,
      previous_teams: this.state.selected_player_teams,
    };
    axios.post("player/move_player_to_another_team_2", payload).then((res) => {
      if (res.data.success == 1) {
        this.getSeasons();
        store.addNotification({
          title: "Profil jucator",
          message: "Echipa jucatorului a fost schimbata cu succes",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      } else {
        store.addNotification({
          title: "Profil jucator",
          message: "Echipa jucatorului nu a fost schimbata cu succes",
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      }
      this.closeMovePlayerPopup();
    });
  };

  closeMovePlayerPopup = () => {
    this.setState({
      movePlayerPopup: false,
      selected_new_team: null,
      keep_in_previous_teams: false,
      selected_player_teams: this.state.player_teams,
    });
  };

  deletePlayerFromTeam = () => {
    const payload = {
      id_player: this.props.match.params.id,
      id_team: this.state.delete_from_team.value,
    };
    axios.post(`player/delete_player_from_team_2`, payload).then((res) => {
      if (res.data.success == 1) {
        this.getSeasons();
        store.addNotification({
          title: "Profil jucator",
          message: "Jucatorul a fost eliminat cu succes din echipa!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      } else {
        store.addNotification({
          title: "Profil jucator",
          message: "Jucatorul nu a fost eliminat cu succes din echipa!",
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      }
      this.closeDeletePlayerFromTeam();
    });
  };

  closeDeletePlayerFromTeam = () => {
    this.setState({
      delete_player_from_team_popup: false,
      delete_from_team: null,
    });
  };

  render() {
    if (this.state.redirectToTeams) {
      return <Redirect to="/club" />;
    }

    const id_player = this.props.match.params.id;
    const { languagePack, token, lang } = this.props;
    const financialAccess = this.isAllowedFinancial();

    return (
      <>
        <div
          className="row dropdown-season-select"
          style={{
            justifyContent:
              this.state.basicInfo.inactive == 1 ? "space-between" : "flex-end",
          }}
        >
          {this.state.basicInfo.inactive == 1 && (
            <div className="inactive-container">
              <div className="inactive-text">
                {"Inactiv de la " +
                  moment(this.state.basicInfo.inactive_from_date).format(
                    "DD.MM.YYYY"
                  )}
                {this.state.basicInfo.inactive_reason != "" &&
                  `, ${this.state.basicInfo.inactive_reason}`}
              </div>
            </div>
          )}
          <a
            target="_blank"
            href={baseUrl(
              `player/generate_player_profile_pdf?id_player=${id_player}&id_season=${this.state.selected_season
              }&type=D&is_tfb=${isTFB() ? 1 : 0}&lang=${lang}&token=${token}`
            )}
          >
            <TFBButton
              color="green"
              renderIcon={() => <TFBIcon name="arrow-down" />}
            >
              {languagePack.download_player_profile}
            </TFBButton>
          </a>
          <Dropdown
            placeholder="Select season"
            selection
            value={this.state.selected_season}
            options={this.state.seasons}
            className="profile-season-dropdown"
            onChange={this.onChangeDropdown}
            name="selected_season"
          />
        </div>
        {this.state.hasAccessToPlayer && !this.state.isLoading ? (
          <div>
            <div className="player-season-teams-history">
              <PlayerTeams
                teams={this.state.player_teams ?? []}
                clubTeams={this.state.club_teams ?? []}
                id_player={this.props.match.params.id}
                refreshList={this.getSeasons}
              />
              <div className="notes-container">
                {this.state.last_note && (
                  <div
                    className="player-notes"
                    style={{
                      cursor: "pointer",
                      border:
                        this.state.last_note.is_critical == 1 &&
                        "2px solid #d91200",
                    }}
                    onClick={() =>
                      this.setState({
                        openAddNotesPopup: true,
                        viewModeNotePopup: true,
                      })
                    }
                  >
                    <span
                      className="ds-main-text-auxiliary"
                      style={{ color: "#b2b2b2" }}
                    >
                      {`${this.state.last_note.user_name}, ${this.state.last_note.date_add}:`}
                    </span>{" "}
                    {this.state.last_note.is_critical == 1 && (
                      <TFBIcon name="flag" color="red" />
                    )}
                    <span
                      className="ds-main-text-auxiliary note-text"
                      style={{ color: "666666" }}
                    >
                      {this.state.last_note.note_text}{" "}
                    </span>
                  </div>
                )}
              </div>
              <TFBButton
                color="green"
                renderIcon={() => <TFBIcon name="report" />}
                className="add-notes-button"
                onClick={() =>
                  this.setState({
                    openAddNotesPopup: true,
                    viewModeNotePopup: false,
                  })
                }
              >
                {languagePack.add_notes}
              </TFBButton>
            </div>
            {/* <AdvancedSeasonStats languagePack={languagePack} /> */}
            {/* <PlayerAdvancedSeasonStats /> */}
            {/* <InstatIndexEvolution />
            <PlayerEventsPitch />
            <PlayerRadarChart languagePack={languagePack} /> */}
            <div className="row">
              <div className="col-lg-6 col-lg-padding no-vertical-padding">
                <div className="row">
                  <div className="col-lg-4 col-lg-padding player-info-widget no-padding-horizontal-mobile-991">
                    {/* Selected season mobile ??*/}

                    <Card style={{ position: "relative" }}>
                      <div
                        style={{
                          height: 310,
                          position: "relative",
                          textAlign: "center",
                        }}
                      >
                        {this.state.addedImage === "" ? (
                          <img
                            onError={this.addDefaultSrc}
                            src={this.state.basicInfo.img_url}
                            className="player-profile-img"
                            alt=""
                          />
                        ) : (
                          <img
                            src={this.state.addedImage}
                            alt=""
                            className="player-profile-img"
                          />
                        )}{" "}
                        {this.state.basicInfo.has_image == 0 ? (
                          <>
                            {this.state.addedImage === "" ? (
                              <label htmlFor="profileImage">
                                <div
                                  className="player-profile-add-image-button"
                                  title={languagePack["edit_photo"]}
                                >
                                  <Icon
                                    name="add"
                                    size="small"
                                    className="add-image-icon"
                                  />
                                </div>{" "}
                              </label>
                            ) : (
                              <>
                                <div
                                  className="cancel-player-profile-add-image-button"
                                  title={"Renunta"}
                                  onClick={() =>
                                    this.setState({ addedImage: "" })
                                  }
                                >
                                  <Icon
                                    name="cancel"
                                    size="small"
                                    className="add-image-icon"
                                  />
                                </div>
                                <div
                                  className="player-profile-add-image-button"
                                  title={languagePack["edit_photo"]}
                                  onClick={this.addImage}
                                >
                                  <Icon
                                    name="check"
                                    size="small"
                                    className="add-image-icon"
                                  />
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {this.state.addedImage === "" ? (
                              <label htmlFor="profileImage">
                                <div
                                  className="player-profile-add-image-button"
                                  title={languagePack["edit_photo"]}
                                >
                                  <Icon
                                    name="pencil"
                                    size="small"
                                    className="add-image-icon"
                                  />
                                </div>
                              </label>
                            ) : (
                              <>
                                <div
                                  className="cancel-player-profile-add-image-button"
                                  title={"Renunta"}
                                  onClick={() =>
                                    this.setState({ addedImage: "" })
                                  }
                                >
                                  <Icon
                                    name="cancel"
                                    size="small"
                                    className="add-image-icon"
                                  />
                                </div>
                                <div
                                  className="player-profile-add-image-button"
                                  title={languagePack["edit_photo"]}
                                  onClick={this.addImage}
                                >
                                  <Icon
                                    name="check"
                                    size="small"
                                    className="add-image-icon"
                                  />
                                </div>
                              </>
                            )}
                          </>
                        )}
                        <input
                          hidden
                          id="profileImage"
                          type="file"
                          ref={this.imgInput}
                          onChange={this.handleChangeImage}
                        />
                      </div>
                      <Card.Content
                        className="player-profile-content"
                        style={{ paddingTop: 10, borderTop: "none" }}
                      >
                        <Card.Header>
                          <div className="profile-name">
                            {this.state.basicInfo.player_name}
                          </div>
                          <div className="profile-position">
                            {this.state.basicInfo.player_position}
                            {this.state.basicInfo.shirt_number > 0 && (
                              <>
                                {"(#"}
                                {this.state.basicInfo.shirt_number}
                                {")"}
                              </>
                            )}
                          </div>
                        </Card.Header>
                        <Card.Description>
                          <div className="basic-info-row">
                            <div className="basic-info-title">
                              {languagePack["birthplace"]}:
                            </div>
                            <div className="basic-info-value">
                              <Flag name={this.state.basicInfo.flag_name} />
                              {this.state.basicInfo.birth_country}
                            </div>
                          </div>
                          <div className="basic-info-row">
                            <div className="basic-info-title">
                              {languagePack["player_age"]}:
                            </div>
                            <div className="basic-info-value">
                              {this.state.basicInfo.birthday ? (
                                <>
                                  {moment().diff(
                                    this.state.basicInfo.birthday,
                                    "years"
                                  )}
                                  {languagePack["age_years"]}
                                </>
                              ) : (
                                "-"
                              )}{" "}
                            </div>
                          </div>
                          <div className="basic-info-row">
                            <div className="basic-info-title">
                              {languagePack["weight"]}:
                            </div>
                            <div className="basic-info-value">
                              {this.state.basicInfo.weight > 0 ? (
                                <>{this.state.basicInfo.weight} kg</>
                              ) : (
                                "-"
                              )}
                            </div>
                          </div>
                          <div
                            className="basic-info-row"
                            style={{ marginBottom: 30 }}
                          >
                            <div className="basic-info-title">
                              {languagePack["height"]}:
                            </div>
                            <div className="basic-info-value">
                              {this.state.basicInfo.height > 0 ? (
                                <>{this.state.basicInfo.height / 100} m</>
                              ) : (
                                "-"
                              )}
                            </div>
                          </div>
                          <div
                            className="edit-player-details-profile"
                            onClick={() =>
                              this.setState({ editDetailsPopup: true })
                            }
                            title={languagePack["edit_details"]}
                          >
                            <Icon
                              name="pencil"
                              size="small"
                              className="edit-details-icon"
                            />
                          </div>
                          <div
                            className="delete-player-details-profile"
                            onClick={() =>
                              this.setState({ deletePlayerPopup: true })
                            }
                            title={languagePack["delete_player"]}
                          >
                            <Icon
                              name="trash"
                              size="small"
                              className="edit-details-icon"
                            />
                          </div>
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </div>
                  {isTFB() && (
                    <div className="col-lg-8 col-lg-padding no-padding-horizontal-mobile-991">
                      <TFBCard>
                        <TFBCardBody>
                          <div
                            // className="card card-custom"
                            style={{ height: "100%" }}
                          >
                            <ContractWidget
                              languagePack={this.props.languagePack}
                              id_player={this.props.match.params.id}
                              id_season={this.state.selected_season}
                              hasFinancialAccess={
                                financialAccess ||
                                !typeof financialAccess == "undefined"
                              }
                              basicInfo={this.state.basicInfo}
                            />
                          </div>
                        </TFBCardBody>
                      </TFBCard>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-lg-12 col-lg-padding no-padding-horizontal-mobile-991">
                    <TransferHistory
                      id_player={id_player}
                      languagePack={languagePack}
                    />
                  </div>
                </div>
              </div>

              {isTFB() && (
                <>
                  <div className="col-lg-6 col-lg-padding">
                    <div
                      className="col-lg-12 no-horizontal-padding"
                      style={{ height: "100%" }}
                    >
                      <div
                        className="card card-custom"
                        style={{ height: "100%" }}
                      >
                        <div className="profile-medical-title-container">
                          <div className="profile-medical-title">
                            <Icon name="doctor" size="large" color="black" />
                            <div>{"Medical"}</div>
                          </div>

                          <TFBButton
                            color="green"
                            renderIcon={() => <TFBIcon name="add" />}
                            onClick={() =>
                              this.setState({ add_new_injury: true })
                            }
                          >
                            {languagePack.active_injuries_add_button}
                          </TFBButton>
                        </div>

                        <TFBDialog
                          open={this.state.add_new_injury}
                          closePopup={() => {
                            this.setState({ add_new_injury: false });
                          }}
                          title={"Medical"}
                          fullWidth={true}
                        // className="pop-up-dialog-box"
                        >
                          <AddInjuryForm
                            from_player_profile={this.props.match.params.id}
                            close_popup={() =>
                              this.setState({ add_new_injury: false })
                            }
                            toggleRefreshInjuries={() =>
                              this.setState({
                                refreshInjuries: !this.state.refreshInjuries,
                              })
                            }
                          />
                        </TFBDialog>

                        {this.state.selected_season != null && (
                          <MedicalPlayerProfile
                            id_player={id_player}
                            id_season={this.state.selected_season}
                            setExpandImage={(param1, param2) => {
                              this.setState({
                                expandedImage: param1,
                                expandedImageSrc: param2,
                              });
                            }}
                            refreshInjuries={this.state.refreshInjuries}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {this.state.expandedImage && (
                    <ExpandedImage
                      closeExpand={() =>
                        this.setState({ expandedImage: false })
                      }
                      image={this.state.expandedImageSrc}
                    />
                  )}
                </>
              )}
            </div>
            {isTFB() && (
              <div className="row">
                <div className="col-lg-6 col-lg-padding">
                  <PlayerTransactions
                    id_team={this.state.basicInfo.id_team}
                    currency={this.props.currentUser.currency}
                    id_club={this.props.currentUser.id_club}
                    id_player={this.props.match.params.id}
                    languagePack={languagePack}
                    has_access={
                      financialAccess || !typeof financialAccess == "undefined"
                    }
                  />
                </div>
                {this.state.show_payments_to_club && (
                  <div className="col-lg-6  col-lg-padding">
                    <div style={{ height: "100%" }}>
                      <PaymentsToClub
                        id_player={id_player}
                        id_season={this.state.selected_season}
                        languagePack={this.props.languagePack}
                        set_show_false={() => {
                          this.setState({
                            show_payments_to_club: false,
                          });
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="row">
              <div className="col-lg-6 col-lg-padding">
                <BasicLastMatchesStats
                  id_player={id_player}
                  id_season={this.state.selected_season}
                  languagePack={this.props.languagePack}
                  stats={this.state.stats?.last_matches_stats}
                  competitions={this.state.competitions}
                  handleFilters={this.updateCompetitions}
                  isLoading={this.state.loading_stats}
                />
              </div>
              <div className="col-lg-6 col-lg-padding">
                <div className="card card-custom">
                  <BasicSeasonStats
                    id_player={id_player}
                    id_season={this.state.selected_season}
                    languagePack={this.props.languagePack}
                    stats={this.state.stats?.current_season_stats}
                    isLoading={this.state.loading_stats}
                  />
                </div>
              </div>
            </div>
            {isTFB() && (
              <div className="row">
                <div className="col-lg-12 col-lg-padding">
                  <div className="card card-custom">
                    <PlayerRadarChart
                      id_player={id_player}
                      id_club={this.props.currentUser.id_club}
                      id_season={this.state.selected_season}
                      languagePack={this.props.languagePack}
                      player_profile={this.state.basicInfo}
                      token={this.props.currentUser.token}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-lg-12 col-lg-padding">
                <div className="card card-custom">
                  <NationalTeamCallUpWidget
                    id_player={this.props.match.params.id}
                  />
                </div>
              </div>
            </div>
            {isTFB() && (
              <div className="row">
                <div className="col-lg-12 col-lg-padding">
                  <div className="card card-custom">
                    <DocumentsWidget id_player={this.props.match.params.id} />
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-lg-12 col-lg-padding">
                <div className="card card-custom">
                  <PlayerVideoHighlightsWidget
                    id_player={this.props.match.params.id}
                  />
                </div>
              </div>
            </div>
            {isTFB() && (
              <div className="row">
                <div className="col-lg-12 col-lg-padding">
                  <div className="card card-custom">
                    <TrainingStatsWidget
                      id_player={this.props.match.params.id}
                      id_season={this.state.selected_season}
                    />
                  </div>
                </div>
              </div>
            )}
            {/* EDIT PLAYER */}
            {this.state.editDetailsPopup && (
              <EditPlayerDetails
                closePopup={() => this.setState({ editDetailsPopup: false })}
                isOpenPopup={this.state.editDetailsPopup}
                name={this.state.basicInfo.player_name}
                position={this.state.basicInfo.player_position}
                player_position_alternative={
                  this.state.basicInfo.player_position_alternative
                }
                player_main_foot={this.state.basicInfo.player_main_foot}
                birthday={this.state.basicInfo.birthday}
                birth_country={this.state.basicInfo.birth_country}
                second_country={this.state.basicInfo.second_country}
                number={
                  this.state.basicInfo.shirt_number > 0
                    ? this.state.basicInfo.shirt_number
                    : ""
                }
                id_player={this.props.match.params.id}
                refreshData={this.getBasicInfo}
                cnp={this.state.basicInfo.cnp}
                fifa_id={this.state.basicInfo.fifa_id}
                frf_list={this.state.basicInfo.frf_list}
                loaned_out={this.state.basicInfo.loaned_out}
                loaned_until={this.state.basicInfo.loaned_until}
                loaned_team={this.state.basicInfo.loaned_team}
                has_professional_license={
                  this.state.basicInfo.has_professional_license
                }
                player_height={this.state.basicInfo.height}
                player_weight={this.state.basicInfo.weight}
                legitimation_date={this.state.basicInfo.legitimation_date}
                enrolment_date={this.state.basicInfo.enrolment_date}
                serial_number={this.state.basicInfo.serial_number}
                phone_number={this.state.basicInfo.phone_number}
                loaned_from={this.state.basicInfo.loaned_from}
                loaned_from_until={this.state.basicInfo.loaned_from_until}
                loaned_from_team={this.state.basicInfo.loaned_from_team}
              />
            )}
            {/* DELETE PLAYER */}
            <Dialog open={this.state.deletePlayerPopup} maxWidth="xl">
              <DialogContent>
                <div className="delete-player-popup-container">
                  <div className="delete-player-header">
                    <div className="delete-player-title">
                      {languagePack["delete_player"]}
                    </div>
                    <Icon
                      name="close"
                      size="large"
                      onClick={() =>
                        this.setState({ deletePlayerPopup: false })
                      }
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div className="delete-player-text">
                    {languagePack["delete_player_popup_text"]}
                  </div>
                  <div className="delete-player-buttons">
                    <Button
                      className="delete-player-button"
                      onClick={() =>
                        this.setState({ deletePlayerPopup: false })
                      }
                      style={{ backgroundColor: "#666666" }}
                    >
                      {languagePack["admin_cancel"]}
                      <Icon
                        name="ban"
                        size="small"
                        className="delete-player-icon"
                      />
                    </Button>
                    <Button
                      className="delete-player-button"
                      style={{ backgroundColor: "var(--primaryColor)" }}
                      onClick={this.deletePlayer}
                    >
                      {languagePack["admin_delete"]}
                      <Icon
                        name="trash"
                        size="small"
                        className="delete-player-icon"
                      />
                    </Button>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
            {/* ADD PLAYER TO ANOTHER TEAM */}
            <Dialog open={this.state.movePlayerPopup} fullWidth maxWidth="sm">
              <DialogContent>
                <div className="delete-player-popup-container">
                  <div className="delete-player-header">
                    <div className="delete-player-title">
                      {languagePack.add_player_to_another_team}
                    </div>
                    <Icon
                      name="close"
                      size="large"
                      onClick={this.closeMovePlayerPopup}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div className="move-player-row">
                    <TFBSelect
                      placeholder={languagePack["select_new_team"]}
                      value={this.state.selected_new_team}
                      options={this.state.club_teams}
                      onChange={(selected) =>
                        this.setState({
                          selected_new_team: selected,
                        })
                      }
                    />
                  </div>
                  <div className="move-player-row">
                    <div className="add-player-professional-license no-padding">
                      <Checkbox
                        label={languagePack.keep_it_in_previous_teams}
                        checked={this.state.keep_in_previous_teams}
                        onChange={(e, data) => {
                          this.setState({
                            keep_in_previous_teams: data.checked,
                            selected_player_teams: this.state.player_teams,
                          });
                        }}
                      />
                    </div>
                    {this.state.keep_in_previous_teams && (
                      <div className="">
                        <TFBSelect
                          value={this.state.selected_player_teams}
                          options={this.state.player_teams}
                          onChange={(selected) =>
                            this.setState({
                              selected_player_teams: selected,
                              keep_in_previous_teams:
                                selected == null ? false : true,
                            })
                          }
                          isMulti
                          isClearable
                          isSearchable
                        />
                      </div>
                    )}
                  </div>
                  <div className="delete-player-buttons">
                    <Button
                      className="delete-player-button"
                      onClick={this.closeMovePlayerPopup}
                      style={{ backgroundColor: "#666666" }}
                    >
                      {languagePack["admin_cancel"]}
                      <Icon
                        name="ban"
                        size="small"
                        className="delete-player-icon"
                      />
                    </Button>
                    <Button
                      className="delete-player-button"
                      style={{ backgroundColor: "#00d948" }}
                      onClick={this.movePlayer}
                      disabled={
                        this.state.selected_new_team == null
                          ? true
                          : this.state.keep_in_previous_teams
                            ? this.state.selected_player_teams.length == 0
                            : false
                      }
                    >
                      {languagePack["admin_save"]}
                      <Icon
                        name="check"
                        size="small"
                        className="delete-player-icon"
                      />
                    </Button>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
            {/* DELETE PLAYER FROM TEAM */}
            <Dialog
              open={this.state.delete_player_from_team_popup}
              maxWidth="xl"
            >
              <DialogContent>
                <div className="delete-player-popup-container">
                  <div className="delete-player-header">
                    <div className="delete-player-title">
                      {"Sterge jucator din echipa"}
                    </div>
                    <Icon
                      name="close"
                      size="large"
                      onClick={this.closeDeletePlayerFromTeam}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div className="delete-player-text">
                    {`Esti sigur ca vrei sa elimini acest jucator din echipa "${this.state.delete_from_team?.label}"? Actiunea este ireversibila.`}
                  </div>
                  <div className="delete-player-buttons">
                    <Button
                      className="delete-player-button"
                      onClick={this.closeDeletePlayerFromTeam}
                      style={{ backgroundColor: "#666666" }}
                    >
                      {languagePack["admin_cancel"]}
                      <Icon
                        name="ban"
                        size="small"
                        className="delete-player-icon"
                      />
                    </Button>
                    <Button
                      className="delete-player-button"
                      style={{ backgroundColor: "#00d948" }}
                      onClick={this.deletePlayerFromTeam}
                    >
                      {languagePack["admin_delete"]}
                      <Icon
                        name="trash"
                        size="small"
                        className="delete-player-icon"
                      />
                    </Button>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
            {this.state.openAddNotesPopup && (
              <AddNotes
                languagePack={languagePack}
                closePopup={() => this.setState({ openAddNotesPopup: false })}
                openPopup={this.state.openAddNotesPopup}
                playerName={this.state.basicInfo.player_name}
                viewModeProp={this.state.viewModeNotePopup}
                noteList={this.state.player_notes}
                id_player={this.props.match.params.id}
                id_user={this.props.currentUser.id_user}
                resfreshNotes={this.getPlayerNotes}
              />
            )}
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Loader
              type="TailSpin"
              color={window.clubCustomization.primary_color}
              height={50}
              width={50}
            />
          </div>
        )}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateQuickSetup,
    },
    dispatch
  );
}

export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    lang: layoutService.lang,
    currentUser: auth.user,
    token: auth.user?.token,
  }),
  mapDispatchToProps
)(PlayerProfile);
