import React, { useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { isValidEmail, isValidPhoneNumberCustom } from "../../../utils/utilFunctions";
import { showNotification, useAxiosPost } from "../../../components/customHooks/useAxiosPost";
import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import useRenderButton from "../../../components/customHooks/useRenderButton";

import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBInput from "../../../components/design-system/Input/TFBInput";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBPhoneInput from "../../../components/design-system/PhoneInput/TFBPhoneInput";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";

const AddMobileUser = (props) => {

    const { openPopup, closePopup, refreshList, languagePack, currentUser, token } = props;

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            surname: "",
            name: "",
            phone_number: "",
            email: "",
            player: null,
            relationship: null,
            players: [],
        }
    );

    const {
        data: playerList,
        loading: isPlayerListLoading,
        refetch: refetchPlayerList
    } = useAxiosGet(`parentsApp/get_players?id_club=${currentUser.id_club}&token=${token}`);

    const {
        data: relationshipList,
        loading: isRelationshipListLoading,
        refetch: refetchRelationshipList
    } = useAxiosGet(`parentsApp/get_relationships?id_club=${currentUser.id_club}`);

    const { postData: addRequest, loading: loadingAddRequest } = useAxiosPost("parentsApp/add_parent_3");

    const handleResponse = (response) => {
        if (response?.success == 1) {
            closePopup();
            refreshList();
            showNotification(
                "success",
                languagePack.mobile_app,
                languagePack.add_mobile_app_user_success
            );
        } else {
            let message = languagePack.add_mobile_app_user_fail;
            if (response?.error_code == "used_phone_number_by_another_user") {
                message = languagePack.used_phone_number_by_another_user;
            } else if (response?.error_code == "used_phone_number_by_another_player") {
                message = languagePack.used_phone_number_by_another_player;
            }
            showNotification(
                "danger",
                languagePack.mobile_app,
                message
            );
        }
    };

    const addUser = () => {
        const payload = {
            id_club: currentUser.id_club,
            name: state.name,
            surname: state.surname,
            phone_number: state.phone_number,
            email: _.trim(state.email),
            players: [...[{ id_player: state.player?.value, relationship: state.relationship?.value }], ..._.map(state.players, e => ({ id_player: e.player?.value, relationship: e.relationship?.value }))]
        };

        addRequest(payload, handleResponse);
    }

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => addUser(),
        "green",
        "add",
        languagePack.admin_add,
        {},
        true,
        _.size(_.trim(state.name)) == 0 || _.size(_.trim(state.surname)) == 0 || _.size(_.trim(state.phone_number)) == 0 || !isValidPhoneNumberCustom(_.trim(state.phone_number)) || _.size(_.trim(state.email)) == 0 || !isValidEmail(_.trim(state.email)) || state.player == null || state.relationship == null || _.size(_.filter(state.players, e => e.player == null || e.relationship == null)) > 0,
        loadingAddRequest,
    );

    const addAditionalPlayer = () => {
        updateState({ players: [...state.players, [{ player: null, relationship: null }]] })
    }

    const removeAditionalPlayer = (index) => {
        updateState({ players: _.filter(state.players, (e, i) => i != index) })
    }

    const updateAdditionalPlayer = (index, field, selected) => {
        let cloneArray = _.clone(state.players);
        cloneArray[index][field] = selected;
        updateState({ players: cloneArray });
    }

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack["add_user"]}
            open={openPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
        >
            <TFBInput
                label={languagePack.last_name}
                value={state.surname}
                onChange={(e) => updateState({ surname: e.target.value })}
                style={{ marginBottom: 20, width: "100%" }}
            />
            <TFBInput
                label={languagePack.first_name}
                value={state.name}
                onChange={(e) => updateState({ name: e.target.value })}
                style={{ marginBottom: 20, width: "100%" }}
            />
            <TFBPhoneInput
                label={languagePack.phone_number}
                defaultCountry="RO"
                countries={["RO"]}
                initialValueFormat="national"
                placeholder={"Ex: 0712 345 678"}
                value={state.phone_number}
                onChange={(e) => updateState({ phone_number: e ?? "" })}
                style={{ marginBottom: 20 }}
            />
            {!isValidPhoneNumberCustom(state.phone_number) && (
                <div className="ui red message">{languagePack.wrong_phone_number}</div>
            )}
            <TFBInput
                label={languagePack.Email}
                value={state.email}
                onChange={(e) => updateState({ email: e.target.value })}
                componentStyle={{ marginBottom: 20, width: "100%" }}
                style={{ width: "100%" }}
            />
            {_.size(state.email) > 0 && !isValidEmail(state.email) && (
                <div className="ui red message">{languagePack.wrong_email}</div>
            )}
            <TFBSelect
                label={languagePack.player}
                value={state.player}
                options={playerList}
                onChange={(selected) => updateState({ player: selected })}
                style={{ component: { marginBottom: 20, } }}
            />
            <TFBSelect
                label={languagePack.player_relative}
                value={state.relationship}
                options={relationshipList}
                onChange={(selected) => updateState({ relationship: selected })}
                style={{ component: { marginBottom: 20, } }}
            />
            {_.map(state.players, (row, index) => (
                <div
                    className="df-g20 df-center"
                    key={"venue-child-" + index}
                >
                    <div style={{ width: "100%" }}>
                        <TFBSelect
                            label={languagePack.player}
                            placeholder={languagePack.player}
                            value={row?.player ?? null}
                            options={playerList}
                            onChange={(selected) => updateAdditionalPlayer(index, "player", selected)}
                            style={{ component: { marginBottom: 20 } }}
                        />
                        <TFBSelect
                            label={languagePack.player_relative}
                            placeholder={languagePack.player_relative}
                            value={row?.relationship ?? null}
                            options={relationshipList}
                            onChange={(selected) => updateAdditionalPlayer(index, "relationship", selected)}
                            style={{ component: { marginBottom: 20 } }}
                        />
                    </div>
                    <TFBIconButton
                        name="x-large"
                        color="red"
                        onClick={() => removeAditionalPlayer(index)}
                    />
                </div>
            ))}
            <TFBButton
                color="lightGray"
                renderIcon={() => <TFBIcon name="add" color="gray" />}
                onClick={addAditionalPlayer}
                disabled={state.player == null || state.relationship == null || _.size(_.filter(state.players, e => e.player == null || e.relationship == null)) > 0}
            >
                {languagePack.add_player}
            </TFBButton>
        </TFBDialog>
    );
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyRates: auth?.user?.currency_rates
}))(AddMobileUser);
