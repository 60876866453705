import React, { useReducer } from "react";
import _ from "lodash";
import { store } from "react-notifications-component";
import { connect } from "react-redux";

import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBInput from "../../../components/design-system/Input/TFBInput";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import useRenderButton from "../../../components/customHooks/useRenderButton";
import { useAxiosPost } from "../../../components/customHooks/useAxiosPost";

function EditTagCategoryPopup({
  languagePack,
  category,
  closePopup,
  token,
  refetchFilters,
}) {
  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      category: category,

      newTags: [],

      toDeleteTags: [],
    }
  );

  const {
    data: EditTagsResponse,
    error: EditTagsError,
    loading: editTagsLoading,
    postData: postEditTags,
  } = useAxiosPost("training/edit_training_section_tag_nomenclature");

  const { renderBtn: BtnLeft } = useRenderButton(
    () => closePopup(),
    "darkGray",
    "cancel",
    languagePack.admin_cancel
  );

  const { renderBtn: BtnRight } = useRenderButton(
    () => updateCategory(),
    "green",
    "save",
    languagePack.admin_save,
    {},
    true,
    false,
    editTagsLoading
  );

  function handleChangeTagName(e, index) {
    const children = state.category.children;

    children[index]["label"] = e.target.value;

    updateState({ category: { ...state.category, children: children } });
  }

  function updateCategory() {
    function handleResponse(response) {
      if (response.success === 1) {
        closePopup();
        refetchFilters();
        store.addNotification({
          title: "Secțiuni antrenamente",
          message:
            "Categorie etichete sectiuni antrenament editata cu succes !",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      } else {
        store.addNotification({
          title: "Secțiuni antrenamente",
          message: "Categorie etichete nu a fost editată!",
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      }
    }
    const newTags = _.map(
      _.filter(
        state.category.children,
        (tag) => !Object.hasOwn(tag, "value") && _.size(tag["label"]) > 0
      ),
      (tag) => {
        return { tag_name: tag.label, id_tag_category: state.category.value };
      }
    );

    const existingTags = _.map(
      _.filter(state.category.children, (tag) => Object.hasOwn(tag, "value")),
      (tag) => {
        if (tag.value) return { tag_name: tag.label, id_tag: tag.value };
      }
    );

    const toDelete = _.map(state.toDeleteTags, (tag) => {
      return { id_tag: tag.value };
    });

    const payload = {
      token: token,
      tags: [...newTags, ...existingTags],
      tags_to_delete: toDelete,
    };

    postEditTags(payload, handleResponse);
  }

  return (
    <TFBDialog
      className="pop-up-dialog-box prevent-select-text"
      title={languagePack.edit_category}
      open
      closePopup={closePopup}
      btnLeft={BtnLeft}
      btnRight={BtnRight}
    >
      <div className="pop-up-section">
        <TFBInput
          label={languagePack.category_name}
          placeholder={languagePack.category_name}
          value={state.category.label}
          disabled={state.category.can_edit}
          fullWidth={true}
          onChange={(e) => updateState({ parent_name: e.target.value })}
        />
      </div>
      <div className="pop-up-section">
        <div className="pop-up-section-body">
          {_.map(state.category.children, (elem, index) => (
            <div
              className="pop-up-row df-g20 align-items-center"
              key={"venue-child-" + index}
            >
              <TFBInput
                label={languagePack.label_name}
                placeholder={languagePack.label_name}
                value={elem.label}
                disabled={elem.can_edit}
                fullWidth={true}
                onChange={(e) => handleChangeTagName(e, index)}
              />
              {_.size(state.category.children) > 1 && elem.can_edit && (
                <TFBIconButton
                  name="x-large"
                  color="red"
                  onClick={() => {
                    let children = state.category.children;
                    children.splice(index, 1);
                    let to_delete = [...state.toDeleteTags];
                    if (elem?.value) {
                      to_delete = [...to_delete, elem];
                    }
                    updateState({
                      category: { ...state.category, children: children },
                      toDeleteTags: to_delete,
                    });
                  }}
                />
              )}
            </div>
          ))}
          <TFBButton
            color="lightGray"
            renderIcon={() => <TFBIcon name="add" color="gray" />}
            disabled={_.some(state.category.children, ["label", ""])}
            onClick={() => {
              const children = state.category?.children;
              children && children.push({ label: "" });
              updateState({
                category: { ...state.category, children: children },
              });
            }}
          >
            {languagePack.add_new_label}
          </TFBButton>
        </div>
      </div>
    </TFBDialog>
  );
}
export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(EditTagCategoryPopup);
