import React, { Component } from "react";
import "./AddMatchForm.scss";
import axios from "axios";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import moment from "moment";
import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";
import TFBInput from "../../../../components/design-system/Input/TFBInput";
import TFBDateTimePicker from "../../../../components/design-system/DateTimePicker/TFBDateTimePicker";
import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";
import TFBCheckbox from "../../../../components/design-system/Checkbox/TFBCheckbox";
import MatchResult from "../../../../components/reusable/MatchResults/MatchResult";
import { bindActionCreators } from "redux";
import { updateQuickSetup } from "../../../../utils/api";
import { isTFB } from "../../../../utils/utilFunctions";

class AddMatchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      match_round: "",
      team_home: "",
      team_away: "",
      match_date: null,
      teams: [],
      group: "",
      location: "",
      locations: [],
      add_new_location: false,
      add_venue_to_mng: false,
      approximate_date: false,
      minutes_per_time: "",
      match_formats: [],
      selected_match_format: "",
      other_match_format: false,
      new_match_format: "",

      teamsOptions: [],
      selectedHomeTeam: "",
      selectedAwayTeam: "",

      homeTeamName: null,
      awayTeamName: null,

      locationOptions: [],
      selectedLocation: "",
      invalidDate: false,

      halfHomeScore: null,
      halfAwayScore: null,
      finalHomeScore: null,
      finalAwayScore: null,
    };
  }

  componentDidMount = () => {
    this.getTeams();
    this.getLocations();
    this.getMatchFormats();
  };

  handleDateChange = (value, isValid) => {
    if (isValid.validationError == null) {
      this.setState({ match_date: value, invalidDate: false });
      return;
    }

    this.setState({ invalidDate: true });
  };

  handleChangeMinutesPerTime = (e) => {
    e.preventDefault();
    let numbers = /^\s*\d*\s*$/;
    if (e.target.value.match(numbers)) {
      this.setState({ minutes_per_time: e.target.value });
    }
  };

  handleChangeMatchFormat = (selectedFormat) => {
    if (selectedFormat.value == "0") {
      this.setState({
        selected_match_format: selectedFormat,
        other_match_format: true,
      });
      return;
    }
    this.setState({
      selected_match_format: selectedFormat,
      other_match_format: false,
    });
  };

  handleChangeLocation = (selected) => {
    if (selected.value == "0") {
      this.setState({ add_new_location: true });
      this.setState({ location: "" });
    } else {
      this.setState({ add_new_location: false });
      this.setState({ location: selected.value });
    }
  };

  getTeams = () => {
    axios
      .get(
        `matches/get_competition_teams2?id_competition=${this.props.id_competition}&id_club=${this.props.currentUser.id_club}`
      )
      .then((res) => {
        let compTeams = res.data.map((team) => {
          return {
            value: team.id_manual_competition_team,
            label: team.team_name,
          };
        });
        this.setState({ teams: res.data, teamsOptions: compTeams });
      })
      .catch((err) => console.log(err));
  };

  getMatchFormats = () => {
    axios
      .get(`matches/manual_match_formats`)
      .then((res) => {
        let matchFormats = [
          ...res.data.formats,
          { value: "0", label: "Alt format" },
        ];
        this.setState({
          match_formats: matchFormats,
          selected_match_format: matchFormats[0],
        });
      })
      .catch((err) => console.log(err));
  };

  getLocations = () => {
    axios
      .get(`site/get_locations?id_club=${this.props.currentUser.id_club}`)
      .then((res) => {
        let locationOptions = res.data.map((location) => {
          return { label: location.venue_name, value: location.id_venue };
        });

        locationOptions.unshift({
          label: "Add new location",
          value: 0,
        });

        this.setState({
          locations: res.data,
          locationOptions: locationOptions,
        });
      })
      .catch((err) => console.log(err));
  };

  addNewMatch = () => {
    let payload = {
      id_competition: this.props.id_competition,
      minutes_per_time: this.state.minutes_per_time,
      round: this.state.match_round,
      id_team_home: this.state.selectedHomeTeam,
      id_team_away: this.state.selectedAwayTeam,
      match_date: moment(this.state.match_date.$d).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      approximate_date: { true: 1, false: 0 }[this.state.approximate_date],
      id_competition_group: this.state.group,
      is_tfb: isTFB(),
    };

    if (this.state.add_new_location) {
      payload.venue_name = this.state.location;
      payload.id_club = this.props.currentUser.id_club;
      payload.add_venue_to_mng = { false: "0", true: "1" }[
        this.state.add_venue_to_mng
      ];
    } else {
      payload.id_venue = this.state.location;
      payload.id_club = this.props.currentUser.id_club;
    }

    payload.match_format = this.state.selected_match_format.value;
    payload.another_format_name = this.state.other_match_format
      ? this.state.new_match_format
      : null;

    payload.home_team_goals_half_time = this.state.halfHomeScore;
    payload.team_away_goals_half_time = this.state.halfAwayScore;
    payload.team_home_goals = this.state.finalHomeScore;
    payload.team_away_goals = this.state.finalAwayScore;

    axios.post("matches/add_match_2", payload).then((res) => {
      if (res.data.success == 1) {
        store.addNotification({
          title: this.props.languagePack["fixtures_schedule"],
          message: this.props.languagePack["match_successfully_added"],
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });

        let clubQuickSetup = res.data.club_quick_setup;
        this.props.updateQuickSetup({
          ...clubQuickSetup,
          isSetupOpen: false,
        });

        this.props.closePopup();
        this.props.getMatches();
        this.props.refreshGroupsResults();
      }
    });
  };

  renderBtn = (position, onClick) => {
    return (
      <TFBButton
        onClick={() => {
          onClick();
          this.setState({ match_round: "", approximate_date: false });
        }}
        endIcon="true"
        color={position == "left" ? "darkGray" : "green"}
        renderIcon={() => (
          <TFBIcon name={position == "left" ? "cancel" : "add"} />
        )}
        disabled={
          position == "right" &&
          (this.state.selectedHomeTeam == "" ||
            this.state.selectedAwayTeam == "" ||
            this.state.match_date == null ||
            this.state.invalidDate ||
            (this.state.other_match_format &&
              (this.state.new_match_format == null ||
                this.state.new_match_format == "")) ||
            (this.state.add_new_location &&
              (this.state.location == null || this.state.location == "")))
        }
      >
        {position == "left"
          ? this.props.languagePack.Cancel
          : this.props.languagePack.Add}
      </TFBButton>
    );
  };

  render() {
    const { languagePack } = this.props;
    return (
      <TFBDialog
        open={this.props.openPopup}
        fullWidth={true}
        maxWidth="sm"
        closePopup={() => {
          this.props.closePopup();
          this.setState({ match_round: "", approximate_date: false });
        }}
        title={languagePack["add_match"]}
        btnRight={() => this.renderBtn("right", () => this.addNewMatch())}
        btnLeft={() => this.renderBtn("left", () => this.props.closePopup())}
      >
        <div className="add-match-container">
          <div className="add-match-row-dialog">
            <TFBInput
              placeholder={languagePack["round_number_name"]}
              label={languagePack["round_number_name"]}
              value={this.state.match_round}
              style={{ width: "100%" }}
              onChange={(e) => this.setState({ match_round: e.target.value })}
            />
          </div>

          <div className="add-match-row-dialog">
            <TFBSelect
              placeholder={languagePack["home_team"]}
              label={languagePack["home_team"]}
              options={this.state.teamsOptions}
              onChange={(selected) =>
                this.setState({
                  selectedHomeTeam: selected.value,
                  homeTeamName: selected.label,
                })
              }
            />
          </div>

          <div className="add-match-row-dialog">
            <TFBSelect
              placeholder={languagePack["away_team"]}
              label={languagePack["away_team"]}
              options={this.state.teamsOptions}
              onChange={(selected) =>
                this.setState({
                  selectedAwayTeam: selected.value,
                  awayTeamName: selected.label,
                })
              }
            />
          </div>

          {this.state.homeTeamName && this.state.awayTeamName && (
            <div
              className="add-match-row-dialog"
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <MatchResult
                type="half"
                homeTeamName={this.state.homeTeamName}
                awayTeamName={this.state.awayTeamName}
                onChangeHomeScore={(e) =>
                  this.setState({ halfHomeScore: e.target.value })
                }
                onChangeAwayScore={(e) =>
                  this.setState({ halfAwayScore: e.target.value })
                }
              />

              <MatchResult
                type="final"
                homeTeamName={this.state.homeTeamName}
                awayTeamName={this.state.awayTeamName}
                onChangeHomeScore={(e) =>
                  this.setState({ finalHomeScore: e.target.value })
                }
                onChangeAwayScore={(e) =>
                  this.setState({ finalAwayScore: e.target.value })
                }
              />
            </div>
          )}

          <div className="add-match-row-dialog">
            <TFBInput
              placeholder={languagePack.minutes_per_time}
              label={languagePack.minutes_per_time}
              value={this.state.minutes_per_time}
              style={{ width: "100%" }}
              type="number"
              min="0"
              onChange={(e) => this.handleChangeMinutesPerTime(e)}
            />
          </div>

          <div className="add-match-row-dialog">
            <TFBSelect
              placeholder={languagePack.match_format}
              label={languagePack.match_format}
              options={this.state.match_formats}
              value={this.state.selected_match_format}
              onChange={(selected) => this.handleChangeMatchFormat(selected)}
            />
          </div>

          {this.state.other_match_format && (
            <div className="add-match-row-dialog">
              <TFBInput
                placeholder={languagePack.add_match_format}
                label={languagePack.add_match_format}
                value={this.state.new_match_format}
                style={{ width: "100%" }}
                onChange={(e) => {
                  this.setState({ new_match_format: e.target.value });
                }}
              />
            </div>
          )}

          {this.props.groups.length > 0 ? (
            <div className="add-match-row-dialog">
              <TFBSelect
                placeholder={languagePack.select_group}
                label={languagePack.select_group}
                options={this.props.groups}
                onChange={(selected) => {
                  this.setState({ group: selected.value });
                }}
              />
            </div>
          ) : null}
          <div className="add-match-row-dialog">
            <TFBDateTimePicker
              label={languagePack["match_date"]}
              fullWidth={true}
              onChange={this.handleDateChange}
            />
          </div>

          <div
            className="add-match-row-dialog"
            style={{ width: "max-content" }}
            onClick={() =>
              this.setState({
                approximate_date: !this.state.approximate_date,
              })
            }
          >
            <TFBCheckbox
              label={languagePack.date_and_time_are_approximate}
              // startLabel
              onChange={(e) =>
                this.setState({
                  approximate_date: e.target.checked,
                })
              }
              checked={this.state.approximate_date}
            />
          </div>

          <div className="add-match-row-dialog">
            <TFBSelect
              placeholder={languagePack.select_match_location}
              label={languagePack.select_match_location}
              options={this.state.locationOptions}
              onChange={(selected) => this.handleChangeLocation(selected)}
              menuPlacement="auto"
              maxMenuHeight={160}
            />
          </div>
          {this.state.add_new_location && (
            <div style={{ marginBottom: "14px" }}>
              <div className="add-match-row-dialog">
                <TFBInput
                  placeholder={languagePack.new_match_venue}
                  label={languagePack.new_match_venue}
                  value={this.state.location}
                  style={{ width: "100%" }}
                  onChange={(e) => this.setState({ location: e.target.value })}
                />
              </div>

              <div
                style={{ width: "100%", maxWidth: "355px" }}
                onClick={() =>
                  this.setState({
                    add_venue_to_mng: !this.state.add_venue_to_mng,
                  })
                }
              >
                <TFBCheckbox
                  label={languagePack.add_location_to_field_management}
                  onChange={(e) => {
                    this.setState({
                      add_venue_to_mng: e.target.checked,
                    });
                  }}
                  checked={this.state.add_venue_to_mng}
                />
              </div>
            </div>
          )}
        </div>
      </TFBDialog>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateQuickSetup,
    },
    dispatch
  );
}

export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(AddMatchForm);
