import React from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import "../../../../pages/Club/PlayerProfile/PlayerProfile.scss";
import "./PlayerRadarChart.scss";
import { logout } from "./../../../../utils/api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TFBCard from "../../../design-system/Card/TFBCard";
import TFBCardHeader from "../../../design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../design-system/Card/TFBCardBody";
import TFBPlaceholder from "../../../design-system/NoDataPlaceholder/TFBPlaceholder";
import { Switch } from "@material-ui/core";
import TFBSelect from "../../../design-system/TFBSelect/TFBSelect";
import IndividualChart from "./PlyerCharts/IndividualChart";
import TwoPlayersComparison from "./PlyerCharts/TwoPlayersComparison";
import TFBButton from "../../../design-system/Button/TFBButton";
import TFBIcon from "../../../design-system/Icon/TFBIcon";
import EditEvalution from "./PlyerCharts/EditEvalution";

const firstPlayerStyles = {
  placeholder: (provided) => ({
    ...provided,
    color: "#00AAFF",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#00AAFF",
  }),
};

const secondPlayerStyles = {
  placeholder: (provided) => ({
    ...provided,
    color: "#FF9500",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#FF9500",
  }),
};

class PlayerRadarChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {},
      seriesComparison: [],
      optionsCompariosn: {},

      isLoadingData: false,
      noEvaluation: false,
      evaluationdata: [],
      evaluationdataComparison: [],

      switchView: false,

      playerOne: {
        label: this.props.player_profile?.player_name,
        value: this.props.player_profile?.id_player,
      },

      playerTwo: {
        label: this.props.player_profile?.player_name,
        value: this.props.player_profile?.id_player,
      },

      firstEvaluationList: [],
      firstEvaluation: null,
      secondEvaluationList: [],
      secondEvaluation: null,
      playersComparisonNoData: false,

      playersList: [],

      open_edit_evaluation: false,
    };
  }

  componentDidMount() {
    this.getLastEvaluationData();
    this.getPlayerEvaluationList(
      this.state.playerOne.value,
      "firstEvaluationList"
    );
    this.getPlayerEvaluationList(
      this.state.playerOne.value,
      "secondEvaluationList"
    );
    this.getClubPlayers();
  }

  //==================  GET DATA =================
  //==============================================
  getClubPlayers = () => {
    axios
      .get(
        `club/get_club_players?id_club=${this.props.id_club}&token=${this.props.token}&loaned_out=0&type=select`
      )
      .then((res) => {
        this.setState({ playersList: res.data?.list ?? [] });
      })
      .catch((err) => console.log(err));
  };

  getPlayerEvaluationList = (id_player, stateName) => {
    axios
      .get(
        `/player/get_player_evaluation_list?id_player=${id_player}&token=${this.props.token}`
      )
      .then((res) => {
        if (stateName == "firstEvaluationList") {
          this.setState({
            firstEvaluation: res.data.length > 0 ? res.data[0] : null,
          });
        }
        if (stateName == "secondEvaluationList") {
          let secondEvaluation =
            id_player != this.state.playerOne.value
              ? res.data[0]
              : res.data[1] ?? res.data[0];

          this.setState({
            secondEvaluation: res.data.length > 0 ? secondEvaluation : null,
          });
        }

        this.setState({ [stateName]: res.data }, () =>
          this.getEvaluationComparison()
        );
      })
      .then(this.setState({ isLoadingData: true }))
      .catch((err) => console.log(err));
  };

  getEvaluationComparison = () => {
    if (this.state.firstEvaluation && this.state.secondEvaluation) {
      axios
        .get(
          `/player/get_players_evaluation_comparison?id_player_1=${this.state.playerOne.value}&id_player_2=${this.state.playerTwo.value}&id_test_1=${this.state.firstEvaluation.value}&id_test_2=${this.state.secondEvaluation.value}&token=${this.props.token}`
        )
        .then((res) => {
          this.setState({
            playersComparisonNoData: false,
            evaluationdataComparison: res.data.evaluation_data,
            seriesComparison: [
              {
                name: this.state.playerOne.label,
                data: res.data.player_1.data,
              },
              {
                name: this.state.playerTwo.label,
                data: res.data.player_2.data,
              },
            ],
            optionsCompariosn: {
              chart: {
                height: 100,
                type: "radar",
              },
              responsive: [
                {
                  breakpoint: 946,
                  options: {
                    plotOptions: {
                      radar: {
                        size: 80,
                        polygons: {
                          strokeColors: "#e9e9e9",
                          fill: {
                            colors: ["#f8f8f8", "#f8f8f8"],
                          },
                        },
                      },
                    },
                    chart: {
                      height: 300,
                      type: "radar",
                    },
                  },
                },
              ],

              dataLabels: {
                enabled: true,
              },
              plotOptions: {
                radar: {
                  // size: 150,
                  polygons: {
                    strokeWidth: 0,
                    strokeColors: "#e9e9e9",
                    fill: {
                      colors: ["#f8f8f8", "#f8f8f8"],
                    },
                  },
                },
              },
              fill: {
                opacity: 0,
              },
              colors: ["#00AAFF", "#FF9500"], // color of chart itself
              markers: {
                size: 6,
                colors: ["#00AAFF", "#FF9500"],
                strokeColor: ["#00AAFF", "#FF9500"], // point color value
                strokeWidth: 6,
              },
              stroke: {
                show: true,
                curve: "smooth",
                lineCap: "butt",
                colors: undefined,
                width: 1,
                colors: ["#EDEDED", "#EDEDED"],
                dashArray: 0,
              },
              tooltip: {
                y: {
                  formatter: function(val) {
                    return val;
                  },
                },
              },
              xaxis: {
                categories: res.data.player_1.categories,
              },
            },
          });
        })
        .then(this.setState({ isLoadingData: true }))
        .catch((err) => console.log(err));
    } else {
      this.setState({ playersComparisonNoData: true });
    }
  };

  getLastEvaluationData = () => {
    axios
      .get(
        `player/get_last_evaluation_data?id_player=${this.props.id_player}&token=${this.props.token}`
      )
      .then((res) => {
        if (Object.keys(res.data).length === 0) {
          this.setState({ noEvaluation: true });
        } else {
          this.setState({
            evaluationdata: res.data.evaluation_data,
            series: [
              {
                name: "Notă evaluare",
                data: res.data.data,
              },
            ],
            options: {
              chart: {
                height: 100,
                type: "radar",
              },
              responsive: [
                {
                  breakpoint: 946,
                  options: {
                    plotOptions: {
                      radar: {
                        size: 80,
                        polygons: {
                          strokeWidth: 0,
                          strokeColors: "#e9e9e9",
                          fill: {
                            colors: ["#f8f8f8", "#f8f8f8"],
                          },
                        },
                      },
                    },
                    chart: {
                      height: 300,
                      type: "radar",
                    },
                  },
                },
              ],

              dataLabels: {
                enabled: true,
              },
              plotOptions: {
                radar: {
                  size: 150,
                  polygons: {
                    strokeWidth: 0,
                    strokeColors: "#e9e9e9",
                    fill: {
                      colors: ["#f8f8f8", "#f8f8f8"],
                    },
                  },
                },
              },

              title: {
                text: `${res.data.title.text}`,
              },
              subtitle: {
                text: `${res.data.subtitle.text}`,
                align: "left",
              },
              colors: ["#0acc54"], // color of chart itself
              markers: {
                size: 4,
                colors: ["#fff"],
                strokeColor: "#0acc54", // point color value
                strokeWidth: 6,
              },
              tooltip: {
                y: {
                  formatter: function(val) {
                    return val;
                  },
                },
              },
              xaxis: {
                categories: res.data.categories,
              },
            },
          });
        }
      })
      .then(this.setState({ isLoadingData: true }))
      .catch((err) => console.log(err));
  };

  openEditEvaluation = () => {
    this.setState({ open_edit_evaluation: true });
  };

  closeEditEvaluation = () => {
    this.setState({ open_edit_evaluation: false });
  };

  //================== HANDLERS ==================
  //==============================================

  handleChangeCheckbox = () => {
    this.setState({
      switchView: !this.state.switchView,
    });
  };

  //==================  RENDER  ==================
  //==============================================

  render() {
    const { languagePack } = this.props;
    let { firstEvaluationList, playersList } = this.state;

    return (
      <TFBCard>
        <TFBCardHeader title={languagePack.evaluation}>
          {!this.state.switchView && !this.state.noEvaluation && (
            <TFBButton
              color="green"
              renderIcon={() => <TFBIcon name="edit" />}
              onClick={this.openEditEvaluation}
            >
              {languagePack.edit_evaluation}
            </TFBButton>
          )}
        </TFBCardHeader>
        <TFBCardBody className="player-radar-chart-evaluation-widget">
          {this.state.noEvaluation ? (
            <TFBPlaceholder text={languagePack.no_data_evaluation} />
          ) : (
            <div id="chart">
              <div className="evaluation-switch">
                <span
                  className="ds-secondary-text-auxiliary"
                  onClick={() => this.setState({ switchView: false })}
                >
                  {languagePack.Individual}
                </span>
                <Switch
                  checked={this.state.switchView}
                  onChange={this.handleChangeCheckbox}
                  color="primary"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <span
                  className="ds-secondary-text-auxiliary"
                  onClick={() => this.setState({ switchView: true })}
                >
                  {languagePack.compare_two_players}
                </span>
              </div>

              {/*================ TWO PLAYERS COMPARISON =================*/}
              {/*=========================================================*/}
              {this.state.switchView && (
                <>
                  {/*================ SELECT LIST =================*/}
                  {/*==============================================*/}
                  <div className="players-to-compare animate-slide">
                    <TFBSelect
                      className="first-player"
                      placeholder={languagePack.first_player}
                      label={languagePack.first_player}
                      styles={firstPlayerStyles}
                      defaultValue={this.state.playerOne}
                      isDisabled={true}
                    />
                    <TFBSelect
                      className="first-player-eval-date"
                      value={this.state.firstEvaluation}
                      placeholder={languagePack.evaluation_date}
                      label={languagePack.evaluation_date}
                      options={firstEvaluationList}
                      onChange={(selected) =>
                        this.setState({ firstEvaluation: selected }, () =>
                          this.getEvaluationComparison()
                        )
                      }
                      styles={firstPlayerStyles}
                    />
                    <TFBSelect
                      className="second-player"
                      value={this.state.playerTwo}
                      placeholder={languagePack.second_player}
                      label={languagePack.second_player}
                      options={playersList}
                      styles={secondPlayerStyles}
                      onChange={(selected) => {
                        this.setState({ playerTwo: selected }, () =>
                          this.getPlayerEvaluationList(
                            this.state.playerTwo.value,
                            "secondEvaluationList"
                          )
                        );
                      }}
                    />
                    <TFBSelect
                      className="second-player-eval-date"
                      value={this.state.secondEvaluation}
                      placeholder={languagePack.evaluation_date}
                      label={languagePack.evaluation_date}
                      options={this.state.secondEvaluationList}
                      onChange={(selected) =>
                        this.setState({ secondEvaluation: selected }, () => {
                          this.getEvaluationComparison();
                        })
                      }
                      styles={secondPlayerStyles}
                    />
                  </div>

                  {!this.state.playersComparisonNoData ? (
                    // ================ CHART =================
                    // ==============================================
                    <TwoPlayersComparison
                      optionsCompariosn={this.state.optionsCompariosn}
                      seriesComparison={this.state.seriesComparison}
                      evaluationdataComparison={
                        this.state.evaluationdataComparison
                      }
                    />
                  ) : (
                    <TFBPlaceholder
                      text={languagePack.player_atleast_one_evaluation}
                      style={{ marginTop: "50px" }}
                    />
                  )}
                </>
              )}

              {/*================ INDIVIDUAL COMPARISON ==================*/}
              {/*=========================================================*/}
              {!this.state.switchView && (
                <IndividualChart
                  options={this.state.options}
                  series={this.state.series}
                  evaluationdata={this.state.evaluationdata}
                />
              )}
            </div>
          )}
        </TFBCardBody>

        {this.state.open_edit_evaluation && (
          <EditEvalution
            openPopup={this.state.open_edit_evaluation}
            closePopup={this.closeEditEvaluation}
            refreshList={this.getLastEvaluationData}
            data={this.state.evaluationdata}
          />
        )}
      </TFBCard>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(PlayerRadarChart);
